import React, {useEffect, useState} from 'react';
import "./Resume.css";

const Resume=()=>{
    const [showing, setShowing] = useState(false);
    useEffect(()=>{
        let homeLink = document.getElementById("homeLink");
        let projectsLink = document.getElementById("projectsLink");
        let resumeLink = document.getElementById("resumeLink");
        let contactLink = document.getElementById("contactLink");
  
        homeLink.setAttribute("class", "navLink");
        projectsLink.setAttribute("class", "navLink");
        resumeLink.setAttribute("class", "navLink active");
        contactLink.setAttribute("class", "navLink");
          
      },[])
    const displayEmbedded=()=>{
        let embedded= document.getElementById("resumè");
        let resTab=document.getElementById("resume");
        if(embedded.classList.contains("displayRes")){
            setShowing(false);
            embedded.setAttribute("class", "resume");
            resTab.setAttribute("class", "tab resumeTab");
        }else{
            setShowing(true);
            embedded.setAttribute("class", "displayRes");
            resTab.setAttribute("class", "tab embResTab");

        }
    }
    const renderViewButton=()=>{
        if(showing){
            return("Hide resumè")
        }else{
            return("View Resumè Here")
        }
    }
    return( 
        <>
        <section class="wrapper">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        </section>
        <div id="resume" className="tab resumeTab">
            <div id="resumè" className="resume">
                <iframe title="CaloResume" src="/resume.pdf" type="application/pdf" width="80%" height="800px" />
            </div>
            <div className="resumeButtons">
                <button className="resBtn resOne"  onClick={()=>displayEmbedded()}>{renderViewButton()}</button>
                <a href="/resume.pdf" download="AnthonyCalo.pdf"><button className="resBtn resTwo">Download Resumè PDF</button></a>
            </div>
            
        </div>
        </>
    )
}


export default Resume;