import React, {useEffect, useState} from 'react';
import "./Contact.css";
import axios from 'axios';

const Contact=()=>{
    useEffect(()=>{
        let homeLink = document.getElementById("homeLink");
        let projectsLink = document.getElementById("projectsLink");
        let resumeLink = document.getElementById("resumeLink");
        let contactLink = document.getElementById("contactLink");
  
        homeLink.setAttribute("class", "navLink");
        projectsLink.setAttribute("class", "navLink");
        resumeLink.setAttribute("class", "navLink");
        contactLink.setAttribute("class", "navLink active");
          
      },[])
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [sentAlready, setSent] = useState(false);
    const [thanksMessage, setThanks] = useState("Thanks for your message!")
    const warnMessage=()=>{
        let warning = document.getElementById('messageWarning');
        warning.setAttribute("class", "warningShow");
    }
    
    const onSubmit=async (event)=>{
        event.preventDefault();
        const payload={
            name: name,
            email: email,
            message, message
         }
        if(!payload.message){
            warnMessage();
        }else{
            try {
                setSent(true)
                await axios.post('https://submit-form.com/hTLTfGSS', payload).then((res)=>{
                    if(res){
                        setSent(true);
                    }
                })

            } catch(err){
                console.log(err);
                setThanks("There was an error sending message")
            }
        }
        
        console.log(payload);
    }
    const renderContact=()=>{
        if(!sentAlready){
            return( 
                <div className="tab contactTab">
            <div className="contactFormDiv" id="contactFormId">
                <form onSubmit={onSubmit} className="contactForm">
                    <div className="formItem formName">
                        <label className="labelName" for="name">Name</label>
                        <input 
                            className="contactName contactItem"
                            type="text" 
                            id="name" name="name" 
                            value={name} 
                            onChange={(e)=>setName(e.target.value)} placeholder="Name" required="" />
                    </div>
                    <div className="formItem formEmail">
                        <label className="labelEmail" for="email">Email</label>
                        <input 
                            className="contactEmail contactItem" 
                            type="email" id="email" name="email" 
                            placeholder="Email"
                            value={email} 
                            onChange={(e)=>setEmail(e.target.value)} />
                    </div>
                    <div className="formItem formMessge">
                    <label className="labelMessage" for="message">Message</label>
                        <textarea 
                            placeholder="Message"
                            id="message" name="message" 
                            className="contactItem contactMessage"
                            value={message} 
                            onChange={(e)=>setMessage(e.target.value)}></textarea>
                    <p className="warning" id="messageWarning">Please don't send a blank message</p>

                    </div>
                        <button type="submit" className="formBtn">Send</button>
                </form>
            </div>
        </div>
            )
        }else{
            return( 
                <div className="sentAlready">
                    <button className="sentAlreadyBtn">{thanksMessage}</button>
                </div>
            )
        }
    }
    return(
        <>
        <section class="wrapper">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        </section>
        {renderContact()}
        </>
    )
}

export default Contact;