import React from 'react';
import ReactDOM from 'react-dom';
import history from '../../history';
import "./PModal.css"
import ImgCar from '../ImageCar';
import nodejs from "../assets/nodejs.png"
import stonks2 from "../assets/stonks2.PNG";
import stonks3 from "../assets/stonks3.PNG";
import stonks4 from "../assets/stonks4.PNG";
import stonks5 from "../assets/stonks5.PNG";
import stonksSearch from "../assets/stonksSearch.PNG";
import stonksLimit from "../assets/stonksLimit.PNG";
import react from "../assets/react.png";
import express from "../assets/express.png";
import mongo from "../assets/mongodb.png";
import passport from "../assets/passportjs.png";

const StockModal=(props)=>{
    const exitModal=()=>{
        history.push("/projects");
        window.location.reload();
    }
    
    return ReactDOM.createPortal(
    <div className="modal" onClick={()=>exitModal()}>
    <section class="wrapper">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      </section>
        <div onClick={(e)=> e.stopPropagation()} className="inner-modal">
            <ImgCar srcs={[stonks2, stonks3, stonks4, stonks5,  stonksSearch]}/>
            <div className="modalBody">
                <h1 className="stockHeader">Stock Site</h1>
               
                <div className="aboutProject">
                
                <h3>About application</h3>
                <p className="processEssay">
                Site uses multiple API's and to get stock data and render it.
                Clean re-usable code. Authentication with mongoDB and passport.js.
                Sign in to create custom watch list. Search Bar component allow user to search and add stocks 
                to personal stock list. Also a dark theme.
                Graph with multiple options for time period. Graphs created with chart.js.
                <br/>

                <br/>
                *Not hosted live. Finance API's are very expensive
                </p>
                </div>
                
                
                
                <div className="techUsedModal">
                    <h3>Built from scratch with: </h3>
                    <div className="modalTechList">
                    <img className="modalTechImage" alt="react" src={react}></img>
                    <img className="modalTechImage" alt="node" src={nodejs}></img>
                    <img className="modalTechImage" alt="express" src={express}></img>
                    <img className="modalTechImage" alt="ts" src={mongo}></img>
                    <img className="modalTechImage" alt="ts" src={passport}></img>
                </div>
                <div className="modalBtns">
                    <a href="https://www.github.com/AnthonyCalo/Stonks" target="_blank" rel="noreferrer"><button className="modalBtn stockBtn">Github</button></a>
                    <button className="modalBtn stockBtn" onClick={()=>exitModal()}>Close Window</button>
                </div>
                </div>
            </div>
        </div>
    </div>, document.getElementById('modal'))

}
export default StockModal;