import React, {useEffect, useState} from 'react';
import SideNav from './components/SideNav';
import Example from "./components/Loading"
import Content from './components/Content';
import {useLocation} from "react-router-dom";



const App=()=>{
  const [loading, setLoading]= useState(true);
  let location = useLocation();
  useEffect(()=>{
    //site reloads when clicking on project modal. This makes it so loading screen only occurs for initial start
    if(location.pathname.includes("/projects")){
      setLoading(false);
    }
    setTimeout(()=>{
      setLoading(false);
    }, 1000)
  }, [])
  const renderSite=()=>{
    if(loading){
      return( 
        <Example />
      )
    }else{
      return(
    
        <div className="myContainer">
          
          <SideNav />
          <Content />
          </div>
    
          
          );
    }
  }
  return( 
    renderSite()
  )
}

export default App;
