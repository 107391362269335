import React from 'react';
import ReactDOM from 'react-dom';
import history from '../../history';
import "./PModal.css"
import ImgCar from '../ImageCar';
import nodejs from "../assets/nodejs.png"
import bookhome from "../assets/booksHome.PNG"
import books2 from "../assets/books2.PNG"
import books3 from "../assets/books3.PNG"
import books4 from "../assets/books4.PNG"

import react from "../assets/react.png";
import express from "../assets/express.png";
import mongo from "../assets/mongodb.png";
import passport from "../assets/passportjs.png";
import graphql from "../assets/graphql.png"


const BookModal=(props)=>{
    const exitModal=()=>{
        history.push("/projects");
        window.location.reload();
    }
    
    return ReactDOM.createPortal(
    <div className="modal" onClick={()=>exitModal()}>
    <section class="wrapper">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      </section>
        <div onClick={(e)=> e.stopPropagation()} className="inner-modal">
            <ImgCar srcs={[bookhome, books2, books3, books4]}/>
            <div className="modalBody">
                <h1 className="bookHeader">BookShelf GraphQl</h1>
                <span className="linkModal"><a rel="noreferrer" target="_blank" href="https://inspiring-newton-5fe36b.netlify.app/">Live Site</a></span>
               
                <div className="aboutProject">
                
                <h3>About application</h3>
                <p className="processEssay">
                This sites front end is built with react. It also uses ApolloClient to manage state and make graphql querys. 
                This sites main technical feat/ challenge is the backend however. There is an authentication schema  and ability to make an array of query's. 
                This was accomplished with mongodb, graphql, and express-session. Definitely some bugs along the way and had to really dive into the documentation.
                The result however is a site that loads quickly updates query's
                everytime information is updated. 
                <br/>
                <br/>
                To use the sight just sign in search for books. You can add them to either your reading list or bookshelf.
                Books in the bookshelf category have a review page demonstrated in image 3.
                </p>
                </div>
                
                
                
                <div className="techUsedModal">
                    <h3>Built from scratch with: </h3>
                    <div className="modalTechList">
                    <img className="modalTechImage" alt="react" src={react}></img>
                    <img className="modalTechImage" alt="node" src={nodejs}></img>
                    <img className="modalTechImage" alt="express" src={express}></img>
                    <img className="modalTechImage" alt="mongo" src={mongo}></img>
                    <img className="modalTechImage" alt="passport" src={passport}></img>
                    <img className="modalTechImage" alt="graphql" src={graphql}></img>
                </div>
                <div className="modalBtns">
                    <a href="https://inspiring-newton-5fe36b.netlify.app/" target="_blank" rel="noreferrer"><button className="modalBtn bookBtn">Live</button></a>
                    <a href="https://github.com/AnthonyCalo/Library_GraphQL_React" target="_blank" rel="noreferrer"><button className="modalBtn bookBtn">Github</button></a>
                    <button className="modalBtn bookBtn" onClick={()=>exitModal()}>Close Window</button>
                </div>
                </div>
            </div>
        </div>
    </div>, document.getElementById('modal'))

}
export default BookModal;