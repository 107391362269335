import React from 'react';
import ReactLoading from 'react-loading';
import BB from "./Project/assets/blackbelt.png"

 
const Example = () => (
    <div className="loading">
    <img src={BB} className="cust-logo" alt="logo" />
    <ReactLoading type="bars" color='red' height={250} width={375} />
    </div>
);
 
export default Example;