import React,{useEffect} from 'react';
import {
    Switch,
    Route,
    useLocation
  } from "react-router-dom";
import Home from "./Home";
import Projects from './Projects';
import Resume from "./Resume";
import ProjectModal from './Project/modals/ProjectModal';
import FileModal from './Project/modals/FileModal';
import StockModal from './Project/modals/StockModal';
import Contact from "./Contact";
import {TransitionGroup} from "react-transition-group";
import TrackModal from "./Project/modals/Trackmodal";
import BookModal from './Project/modals/BookModal';

const Content=()=>{
    const location = useLocation()
    //handle Click checks the current location and highlights the sidenav based on it
    const handleClick=(selected)=>{
      let homeLink = document.getElementById("homeLink");
      let projectsLink = document.getElementById("projectsLink");
      let resumeLink = document.getElementById("resumeLink");
      let contactLink = document.getElementById("contactLink");
      if(selected.includes("contact")){
          homeLink.setAttribute("class", "navLink");
          projectsLink.setAttribute("class", "navLink");
          resumeLink.setAttribute("class", "navLink");
          contactLink.setAttribute("class", "navLink active");
      }else if(selected.includes("projects")){
          homeLink.setAttribute("class", "navLink");
          projectsLink.setAttribute("class", "navLink active");
          resumeLink.setAttribute("class", "navLink");
          contactLink.setAttribute("class", "navLink");
      }else if(selected.includes("resume")){
          homeLink.setAttribute("class", "navLink");
          projectsLink.setAttribute("class", "navLink");
          resumeLink.setAttribute("class", "navLink active");
          contactLink.setAttribute("class", "navLink");
      }else{
        homeLink.setAttribute("class", "navLink active");
        projectsLink.setAttribute("class", "navLink");
        resumeLink.setAttribute("class", "navLink");
        contactLink.setAttribute("class", "navLink");
    }
    }
    useEffect(()=>{
      handleClick(location.pathname);
    },[])
    return(
    <>
    
      <TransitionGroup className="farts">
        
          <Switch location={location}>
              <Route exact path="/" component={Home} />
              <Route exact path="/projects" component={Projects}/>
              <Route exact path="/projects/chess" component={ProjectModal}/>
              <Route exact path="/projects/encrypt" component={FileModal}/>
              <Route exact path="/projects/stocks" component={StockModal}/>
              <Route exact path="/projects/jobTracker" component={TrackModal}/>
              <Route exact path="/projects/books" component={BookModal}/>
              <Route exact path="/resume" component={Resume}/>
              <Route exact path="/contact" component={Contact}/>
          </Switch>
      </TransitionGroup>
    </>
)
}

export default Content;