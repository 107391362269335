import React from 'react';
import ReactDOM from 'react-dom';
import history from '../../history';
import "./PModal.css"
import ImgCar from '../ImageCar';
import chess from "../assets/chess.PNG";
import chessEngine2 from "../assets/chessEngine2.PNG";
import chessHome from "../assets/chessHome.png";
import chessSide from "../assets/chessSide.PNG";
import nodejs from "../assets/nodejs.png"
import typeScript from "../assets/typeScript.png";
import react from "../assets/react.png";
import express from "../assets/express.png";

const ProjectModal=(props)=>{
    const exitModal=()=>{
        history.push("/projects");
        window.location.reload();
    }
    

    return ReactDOM.createPortal(
    <div className="modal" onClick={()=>exitModal()}>
    <section class="wrapper">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      </section>
        <div onClick={(e)=> e.stopPropagation()} className="inner-modal">
            <ImgCar srcs={[chessHome,chess, chessEngine2, chessSide]}/>
            <div className="modalBody">
                <h1 className="modalHeader">Chess Game Analyzer</h1>
                <span className="linkModal"><a rel="noreferrer" target="_blank" href="https://www.calochess.com">CaloChess.com</a></span>
               
                <div className="aboutProject">
                
                <h3>Using application</h3>

                <p className="processEssay">
                On the homepage there is a list of famous chess games hand picked to be interesting.
                Choose one. Then just press the right arrow to progress through the game. It will play the moves for you.
                Then at any time you can click/ drag pieces to think about what you would have played differently. When you are ready to return to studying how the actual game turned out press 
                the right or left arrow and it will remember the position before you moved the pieces. At any time there is a button that will display the engine suggested best move.
                Demonstrated in image 3.
                </p>
                </div>
                             
                
                
                <div className="techUsedModal">
                    <h3>Built from scratch with: </h3>
                    <div className="modalTechList">
                    <img className="modalTechImage" alt="react" src={react}></img>
                    <img className="modalTechImage" alt="node" src={nodejs}></img>
                    <img className="modalTechImage" alt="express" src={express}></img>
                    <img className="modalTechImage" alt="ts" src={typeScript}></img>
                </div>
                <div className="modalBtns">
                    <a href="https://www.calochess.com" target="_blank" rel="noreferrer"><button className="modalBtn chessBtn">Live</button></a>
                    <a href="https://www.github.com/AnthonyCalo/ChessGameAnalyzerReactNode" target="_blank" rel="noreferrer"><button className="modalBtn chessBtn">Github</button></a>
                    <button className="modalBtn chessBtn" onClick={()=>exitModal()}>Close Window</button>
                </div>
                </div>
            </div>
        </div>
    </div>, document.getElementById('modal'))

}
export default ProjectModal;