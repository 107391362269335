import "./Home.css";
import "./starBack.css";
import {useEffect} from "react";

const Home=()=>{
    const clickProjects=()=>{
      document.getElementById("projectsLink").click();
    }
    useEffect(()=>{
      let homeLink = document.getElementById("homeLink");
      let projectsLink = document.getElementById("projectsLink");
      let resumeLink = document.getElementById("resumeLink");
      let contactLink = document.getElementById("contactLink");

      homeLink.setAttribute("class", "navLink active");
      projectsLink.setAttribute("class", "navLink");
      resumeLink.setAttribute("class", "navLink");
      contactLink.setAttribute("class", "navLink");
        
    },[])
    return( 
      <>
      <section class="wrapper">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      </section>
      <div className="tab homeTab">
        <div className="homeContent">
        <h1 className="hello"><span className="helloIm">Hello, I'm</span> <span className="name">Anthony Calo</span></h1> 
        <h3 className="developer">A software developer</h3>
        </div>
        <button onClick={()=>clickProjects()} className="projectBtn">View my work</button>
      </div>
      </>
    )
}


export default Home;