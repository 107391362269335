import React from 'react';
import "./project.css";
import node from "./assets/node.png"
import typeScript from "./assets/typeScript.png";
import react from "./assets/react.png";
import express from "./assets/express.png";
import mongo from "./assets/mongodb.png";
import passport from "./assets/passportjs.png";
import graphql from "./assets/graphql.png"

const Project=({projectName, projStyleClass, param, description, github, live})=>{
    const renderLive=()=>{
        if(live){
            return( 
                <a className="live overLayLink" href={live} rel="noreferrer" target="_blank">Live</a>
            )
        }else{
            return null;
        }
    }
    const renderModal=()=>{
        if(param){
            return( 
            <a className="overLayLink" href={`/projects/${param}`}>More Details</a>
            )
        }
    }
    const renderTechList=()=>{
        if(projStyleClass==="chess"){
            return(
                <div className="techList">
                    <img className="techImage" alt="react" src={react}></img>
                    <img className="techImage" alt="node" src={node}></img>
                    <img className="techImage" alt="express" src={express}></img>
                    <img className="techImage" alt="ts" src={typeScript}></img>
                </div>
                )
        }else if (projStyleClass==="stonks"){
            return( 
                <div className="techList">
                    <img className="techImage" alt="react" src={react}></img>
                    <img className="techImage" alt="node" src={node}></img>
                    <img className="techImage" alt="express" src={express}></img>
                    <img className="techImage" alt="mongo" src={mongo}></img>
                    <img className="techImage" alt="passport" src={passport}></img>
                </div>
            )
        }else if(projStyleClass==="encrypt"){
            return( 
                <div className="techList">
                    <img className="techImage" alt="react" src={react}></img>
                </div>
            )
        }else if(projStyleClass==="books"){
            return ( 
                <div className="techList">
                    <img className="techImage" alt="react" src={react}></img>
                    <img className="techImage" alt="node" src={node}></img>
                    <img className="techImage" alt="express" src={express}></img>
                    <img className="techImage" alt="mongo" src={mongo}></img>
                    <img className="techImage" alt="passport" src={passport}></img>
                    <img className="techImage" alt="graphql" src={graphql}></img>
                </div>
            )
        }else if(projStyleClass==="jobTrack"){
            return ( 
                <div className="techList">
                    <img className="techImage" alt="react" title="React" src={react}></img>
                    <img className="techImage" alt="node" src={node}></img>
                    <img className="techImage" alt="express" src={express}></img>
                    <img className="techImage" alt="mongo" src={mongo}></img>
                    <img className="techImage" alt="passport" src={passport}></img>
                </div>
            )
        }
    }
    
    return(
        <>
        

        <div className="projectFlex">
                <h2 className="projectName">{projectName}</h2>
            <div className={`project ${projStyleClass}`}>
            <div className="overLay">
                <h4 className="overlayTitle">{projectName}</h4>
                <div className="overLayBody">
                    {description}
                <div className="techUsed">
                    <h4>Built from scratch with: </h4>
                    {renderTechList()}
                </div>
                </div>

                <div className="overLayLinks">
                    {renderLive()}
                    <a className="gitLink overLayLink" target="_blank" rel="noreferrer" href={github}>Github</a>
                    {renderModal()}
                    
                </div>
            </div>
            </div>
        </div>
        </>
        )
}


export default Project;