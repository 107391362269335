import React, {useEffect} from 'react';
import Project from "./Project/Project"
import "./Projects.css";
import "./starBack.css";
import {Link} from "react-router-dom";

const Projects=()=>{
    useEffect(()=>{
        let homeLink = document.getElementById("homeLink");
        let projectsLink = document.getElementById("projectsLink");
        let resumeLink = document.getElementById("resumeLink");
        let contactLink = document.getElementById("contactLink");
  
        homeLink.setAttribute("class", "navLink ");
        projectsLink.setAttribute("class", "active navLink");
        resumeLink.setAttribute("class", "navLink");
        contactLink.setAttribute("class", "navLink");
          
      },[])
    return( 
        <>
        <section class="wrapper">
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        </section>
        <div className="tab projectsTab">
        
            <div className="projects">
                <Project 
                    projectName="Chess Analysis Web Application" 
                    projStyleClass="chess" 
                    param="chess"
                    description="Play through famous chess games. Press right arrow to advance. Click and drag pieces to test hypotheticals.
                    'Press what would engine do?' to get engine suggested best move."
                    github="https://github.com/paulie-walnuts/ChessGameAnalyzerReactNode"
                    live="https://calochess.com"
                    />
                <Project 
                    projectName="Job Application Tracking"
                    projStyleClass="jobTrack"
                    param="jobTracker"
                    description="BACKEND DOWN. NO LONGER HOSTED LIVE. Full stack live site used to track applications.
                    Create username/ password or sign in with google Oauth. Personalized dashboard. Uses MongoDB database backend"
                    github="https://github.com/AnthonyCalo/AppTracker"
                    live="https://apptracker.us"
                />
                <Project 
                    projectName="File Encryption Site"
                    projStyleClass="encrypt"
                    param="encrypt"
                    description="Drag or click to upload a file. Site will encrypt files with almost any extension including .zip, .doc, .xlsx, and .txt files.
                    File never leaves your browser. Password protected. Secure decryption."
                    github="https://github.com/paulie-walnuts/FileEncryption_Browser"
                    live="https://ac-encryption.com"
                    />
                <Project 
                    projectName="Bookshelf Application"
                    projStyleClass="books"
                    param="books"
                    description="BACKEND DOWN. NO LONGER HOSTED LIVE. Authentication schema and database utilizing GraphQL and MongoDB. Front end made with react. Front also uses
                     Apollo server to connect GraphQL. Uses Google books api to search for books. Add a book to your list or book shelf.
                     Add a review or notes to any book on your shelf"
                    github="https://github.com/AnthonyCalo/Library_GraphQL_React"
                    live="https://inspiring-newton-5fe36b.netlify.app/"
                    />
                <Project 
                    projectName="Stock Application"
                    projStyleClass="stonks"
                    param="stocks" 
                    description="Sign in to create a custom stock list. Search to add stocks to list. Displays stock info from multiple API's. 
                    Graphs data with chart.js. Uses cookies to track signed in user."
                    github="https://github.com/paulie-walnuts/Stonks"
                    />


                
                <div className="resButtonProjects">
                    <Link to="/resume"><button className="resBtn">Resumè&#8595;</button></Link>
                </div>
                
                
                   
            </div>

        </div>
        
        </>
    )
}

export default Projects;
