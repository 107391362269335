import React from 'react';
import ReactDOM from 'react-dom';
import history from '../../history';
import "./PModal.css"
import ImgCar from '../ImageCar';
import nodejs from "../assets/nodejs.png"
import jobTrack1 from "../assets/app_track.PNG";
import jobTrack2 from "../assets/jobTrack2.PNG";
import jobTrack3 from "../assets/jobTrack3.PNG";
import jobTrack4 from "../assets/jobTrack4.PNG";
import jobTrack5 from "../assets/jobTrack5.PNG";

import react from "../assets/react.png";
import express from "../assets/express.png";
import mongo from "../assets/mongodb.png";
import passport from "../assets/passportjs.png";

const TrackModal=(props)=>{
    const exitModal=()=>{
        history.push("/projects");
        window.location.reload();
    }
    
    return ReactDOM.createPortal(
    <div className="modal" onClick={()=>exitModal()}>
    <section class="wrapper">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      </section>
        <div onClick={(e)=> e.stopPropagation()} className="inner-modal">
            <ImgCar srcs={[jobTrack1, jobTrack2, jobTrack3, jobTrack4, jobTrack5]}/>
            <div className="modalBody">
                <h1 className="jobHeader">Application Tracking Site</h1>
                <span className="linkModal"><a rel="noreferrer" target="_blank" href="https://www.apptracker.us">AppTracker.us</a></span>
               
                <div className="aboutProject">
                
                <h3>About application</h3>
                <p className="processEssay">
                This site has node.js back-end and a react.js front-end. There is an authentication schema for users to create an account and sign in.
                It uses cookies and creates a session when users login. 
                Utilizes google OAuth as well. It has a filter to just show certain conditions as well as a sort by drop down.
                Site has protected routing with react-router-dom. 
                <br/>
                <br/>
                Live site has a demo user/ pass with example as username and password for test use.
                </p>
                </div>
                
                
                
                <div className="techUsedModal">
                    <h3>Built from scratch with: </h3>
                    <div className="modalTechList">
                    <img className="modalTechImage" alt="react" src={react}></img>
                    <img className="modalTechImage" alt="node" src={nodejs}></img>
                    <img className="modalTechImage" alt="express" src={express}></img>
                    <img className="modalTechImage" alt="ts" src={mongo}></img>
                    <img className="modalTechImage" alt="ts" src={passport}></img>
                </div>
                <div className="modalBtns">
                    <a href="https://www.apptracker.us" target="_blank" rel="noreferrer"><button className="modalBtn trackBtn">Live</button></a>
                    <a href="https://www.github.com/AnthonyCalo/Stonks" target="_blank" rel="noreferrer"><button className="modalBtn trackBtn">Github</button></a>
                    <button className="modalBtn trackBtn" onClick={()=>exitModal()}>Close Window</button>
                </div>
                </div>
            </div>
        </div>
    </div>, document.getElementById('modal'))

}
export default TrackModal;