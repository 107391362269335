import React from 'react';
import "./Side.css";
import {Link} from "react-router-dom";
import gitLogo from "./Project/assets/git_logo.png";

const SideNav=()=>{
    //adds active class to active tab
    const handleClick=(e)=>{
        let homeLink = document.getElementById("homeLink");
        let projectsLink = document.getElementById("projectsLink");
        let resumeLink = document.getElementById("resumeLink");
        let contactLink = document.getElementById("contactLink");

        if(e.innerHTML==="Contact"){
            homeLink.setAttribute("class", "navLink");
            projectsLink.setAttribute("class", "navLink");
            resumeLink.setAttribute("class", "navLink");
            contactLink.setAttribute("class", "navLink active");
        }else if(e.innerHTML==="Home"){
            homeLink.setAttribute("class", "navLink active");
            projectsLink.setAttribute("class", "navLink");
            resumeLink.setAttribute("class", "navLink");
            contactLink.setAttribute("class", "navLink");
        }else if(e.innerHTML==="Projects"){
            homeLink.setAttribute("class", "navLink");
            projectsLink.setAttribute("class", "navLink active");
            resumeLink.setAttribute("class", "navLink");
            contactLink.setAttribute("class", "navLink");
        }else if(e.innerHTML==="Resumè"){
            homeLink.setAttribute("class", "navLink");
            projectsLink.setAttribute("class", "navLink");
            resumeLink.setAttribute("class", "navLink active");
            contactLink.setAttribute("class", "navLink");
        }
    }
    return ( 
        <div className="left-side">
            <div className="navLinkDiv navLinkOne" id="homeBtn">
                <Link id="homeLink" to="/"  onClick={(e)=>handleClick(e.target)} className="navLink">Home</Link>
            </div>
            <div className="navLinkDiv" id="projectsBtn">
                <Link id="projectsLink" to="/projects"  onClick={(e)=>handleClick(e.target)} className="navLink">Projects</Link>
            </div>
            <div className="navLinkDiv" id="resumeBtn">
                <Link id="resumeLink" to="/resume"  onClick={(e)=>handleClick(e.target)} className="navLink">Resumè</Link>
            </div>
            <div className="navLinkDiv" id="aboutBtn">
                <Link id="contactLink" to="/contact" onClick={(e)=>handleClick(e.target)} className="navLink">Contact</Link>
            </div>
            <a href="https://github.com/AnthonyCalo" target="_blank" rel="noreferrer" className="gitLogoA"><img className="gitLogoImg" alt="gitLogo" src={gitLogo}></img></a>
        </div>
    );
}

export default SideNav;

