import React from 'react';
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"

const ImgCar=({srcs})=>{
    const carContent=srcs.map((src,index)=>{
        return ( 
            <div>
            <img className="c-img" alt={index} src={src} />
        </div>
        )
    })
    return( 
        <Carousel>
        {carContent}
        </Carousel>
    )
}

export default ImgCar;