import React from 'react';
import ReactDOM from 'react-dom';
import history from '../../history';
import { useParams } from 'react-router-dom';
import "./PModal.css"
import ImgCar from '../ImageCar';
import react from "../assets/react.png";
import Javascript from "../assets/javascript.png"
import encrypter2 from "../assets/encrypter2.PNG";
import encrypter3 from "../assets/encrypter3.PNG";
import encrypter5 from "../assets/encrypter5.PNG";
import decrypt from "../assets/decrypt.PNG";



const ProjectModal=()=>{
    const exitModal=()=>{
        history.push("/projects");
        window.location.reload();
    }
    return ReactDOM.createPortal(
    <div className="modal" onClick={()=>exitModal()}>
    <section class="wrapper">
      <div id="stars"></div>
      <div id="stars2"></div>
      <div id="stars3"></div>
      </section>
        <div onClick={(e)=> e.stopPropagation()} className="inner-modal">
            <ImgCar srcs={[encrypter2,encrypter3,encrypter5 ,decrypt ]}/>
            <div className="modalBody">
                <h1 className="modalHeaderFile">Browser File Encryption</h1>
                <span className="linkModal"><a rel="noreferrer" target="_blank" href="https://www.ac-encryption.com">ac-encryption.com</a></span>
                <div className="aboutProject">
                
                <h3>Using Application</h3>
                <p className="processEssay">
                This is the most user-friendly file encryption site on the internet. Another benefit is that it's very secure because the site not only doesn't at any point have control of your files, 
                it doesn't store your password either. The site actually hashes the password entered for each file and encrypts or decrypts the file with the hashed password.
                <p className="processEssay">
                To use drag a file to the drop zone or click to select a file from PC's file directory. The site will show you which file selected.
                Then type password and press encrypt file button. This will create an an encrypted version of the file which will be sent your downloads.
                Keep in mind this doesn't destroy the original file. You will have an encrypted file in downloads title "orginialFileName.crypt" as well as the original in original location.
                To decrypt simply get the .crypt file. In the file drop location type in the correct password and the site will return an unencrypted version to your downloads folder.
                </p> 
                </p>
                </div>
                

                <div className="techUsedModal">
                    <h3>Built from scratch with: </h3>
                    <div className="modalTechList">
                    <img className="modalTechImage" alt="react" src={react}></img>
                    <img className="modalTechImage" alt="js" src={Javascript}></img>
                </div>
                <div className="modalBtns">
                    <a href="https://www.ac-encryption.com" target="_blank" rel="noreferrer"><button className="modalBtn encBtn">Live</button></a>
                    <a href="https://www.github.com/AnthonyCalo/FileEncryption_Browser" target="_blank" rel="noreferrer"><button className="modalBtn encBtn">Github</button></a>
                    <button className="modalBtn encBtn" onClick={()=>exitModal()}>Close Window</button>
                </div>
                </div>
            </div>
        </div>
    </div>, document.getElementById('modal'))

}
export default ProjectModal;